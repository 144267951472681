@use "../../config/variables.scss" as vars;

.ViewBtn {
  color: rgb(22, 22, 205);
  cursor: pointer;
}

.ViewBtn:hover {
  text-decoration: underline;
}
